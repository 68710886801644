<template>
  <div>
    <div v-if="fetching" class="ant-modal-root profile-detail_loader">
      <div class="ant-modal-mask">
        <div
          class="ant-modal-wrap d-flex justify-content-center align-items-center"
        >
          <a-spin />
        </div>
      </div>
    </div>

    <a-modal
      v-if="profile"
      :visible="!!profile"
      :footer="false"
      class="profile-detail_modal"
      @change="back"
    >
      <template #title>
        <div class="d-flex justify-content-between align-items-center">
          <div class="title-name">
            <div class="font-weight-bold">
              {{ profile.user_data.firstname + ' ' + profile.user_data.name }}
            </div>
          </div>
          <!-- <div class="title-action mr-3">
            <div v-if="$route.query.store_status === 'active'" @click.prevent="openModalLevel(record, 'update')" class="mb-2">
              <a-button> Update Level </a-button>
            </div>
            <div v-if="$route.query.store_status === 'pending'" @click.prevent="openModalLevel(record, 'confirm')" class="mb-2">
              <a-button> Confirmation Register </a-button>
            </div>
          </div> -->
        </div>
      </template>

      <div class="row mb-5">
        <div class="col-12 col-sm-12 my-1">
          USER DATA
        </div>
        <div
          class="col-12 col-sm-12 d-flex justify-content-between align-items-start flex-wrap mt-1"
        >
          <div class="body-title my-2">
            <div class="my-1 text-muted">
              Firstname:
            </div>
            <div class="my-1">
              {{
                profile.user_data.firstname ? profile.user_data.firstname : '-'
              }}
            </div>
          </div>
          <div class="body-title my-2">
            <div class="my-1 text-muted">
              Name:
            </div>
            <div class="my-1">
              {{ profile.user_data.name }}
            </div>
          </div>
          <div class="body-title my-2">
            <div class="my-1 text-muted">
              Email:
            </div>
            <div class="my-1">
              {{ profile.user_data.email }}
            </div>
          </div>
          <div class="body-title my-2">
            <div class="my-1 text-muted">
              Phone:
            </div>
            <div class="my-1">
              {{ profile.user_data.phone }}
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-12 my-1">
          ADDRESS DATA
        </div>
        <div class="col-12 mt-2">
          <div class="text-muted">
            Address Line 1
          </div>
          <div>{{ profile.address_data.line1 }}</div>
        </div>
        <div class="col-12 mt-2">
          <div class="text-muted">
            Address Line 2
          </div>
          <div>
            {{ profile.address_data.line2 ? profile.address_data.line2 : '-' }}
          </div>
        </div>
        <div
          class="col-12 d-flex justify-content-between align-items-start flex-wrap mt-1"
        >
          <div class="body-title mt-1">
            <div class="my-1 text-muted">
              Subdistrict:
            </div>
            <div class="my-1">
              {{ profile.address_data.subdistrict }}
            </div>
          </div>
          <div class="body-title mt-1">
            <div class="my-1 text-muted">
              District:
            </div>
            <div class="my-1">
              {{ profile.address_data.district }}
            </div>
          </div>
          <div class="body-title mt-1">
            <div class="my-1 text-muted">
              City:
            </div>
            <div class="my-1">
              {{ profile.address_data.city }}
            </div>
          </div>
          <div class="body-title mt-1">
            <div class="my-1 text-muted">
              Province:
            </div>
            <div class="my-1">
              {{ profile.address_data.province }}
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-12 my-1">
          IMAGE DATA
        </div>
        <div
          v-for="(image, index) in profile.image_data"
          :key="index"
          class="col-12 my-2"
        >
          <div class="text-muted">
            {{ image.image_description }}
          </div>
          <div
            style="cursor: pointer"
            @click.prevent="() => handleOpenImage(image.image_url)"
          >
            <img :src="image.image_url" style="width: 50%; height: auto" />
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ReselelrProfile',
  data: function () {
    return {
      fetching: false,
      profile: null,
    }
  },
  computed: {
    ...mapState('settings', ['isMobileView']),
  },
  watch: {
    profile() {
      this.$nextTick(() => {
        const modal = document.querySelector('.profile-detail_modal')

        if (modal) {
          const header = modal.querySelector('.ant-modal-header')
          const body = modal.querySelector('.ant-modal-body')
          const height = header.offsetHeight || 0

          body.style.maxHeight = `calc(100vh - ${height}px)`
        }
      })
    },
    isMobileView(value) {
      this.handleStyle(value)
    },
  },
  mounted() {
    this.fethResellerDetail()
  },
  methods: {
    fethResellerDetail() {
      this.fetching = true
      this.$store
        .dispatch('web_platform_channel/reseller/getResellerDetail', {
          ...this.$route.query,
          ...this.$route.params,
        })
        .then(response => {
          this.profile = response.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.fetching = false
          this.handleStyle(this.isMobileView)
        })
    },
    back() {
      delete this.$route.query.seller_hashid
      delete this.$route.query.store_status

      this.$router.push({
        name: 'web-affiliate.id',
        params: {
          id: this.$route.query.channel_id,
        },
        query: this.$route.query,
      })
      delete this.$route.query.channel_id

      this.$nextTick(() => {
        document.querySelector('body').style.overflow = 'auto'
        window.scrollTo(0, this.$parent.position.y || 0)
      })
    },
    handleStyle(value) {
      this.$nextTick(() => {
        if (!value) {
          const element = document.getElementsByClassName('body-title')
          element.forEach(item => {
            item.style.width = '50%'
          })
        } else {
          const element = document.getElementsByClassName('body-title')
          element.forEach(item => {
            item.style.width = '100%'
          })
        }
      })
    },
    handleOpenImage(url) {
      if (!url) {
        return
      }

      return window.open(url)
    },
  },
}
</script>

<style lang="scss">
.profile-detail_loader {
  .ant-spin-dot-item {
    background-color: #fff;
  }
}

.profile-detail_modal {
  .ant-modal {
    margin: 0 0 0 auto !important;
    top: 0;
    padding-bottom: 0;

    &-content {
      height: 100vh;
      border-radius: 0;
    }

    &-body {
      overflow: auto;
    }
  }
}
</style>
